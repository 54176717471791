<template>
  <el-row :gutter="15">
    <el-col :span="6"
      ><div class="grid-content bg-purple"><span><b>{{key1}}</b></span></div></el-col
    >

    
    <el-col :span="14"><div class="grid-content col-line" ><p class="pcontent" style="margin:3px" >{{value1}}</p></div></el-col>
  </el-row>
</template>
<script>
export default {
  props:['key1','value1'],
  name: "oneCol",
};
</script>
<style>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  /* line-height: 160px; */
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.pcontent{
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
  background-color: #f9fafc9a;
  padding-left: 10px;
  padding-right: 10px 
}

.pcontent:hover{
	white-space:normal;
	overflow:visible;
	text-overflow:clip;
  font: rgb(179, 38, 38);
}
</style>