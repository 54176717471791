<template>
  <!-- components/TopMenu.vue -->
  <div class="menupage">
    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#e6913800"
      text-color="#9dd6ff"
      active-text-color="#46f9d9"
    >
      <p class="logo-title animate__animated animate__rollIn"><i class="el-icon-user"></i>LISITE</p>
      <el-menu-item index="1" class="animate__animated animate__rubberBand" style="margin-left: 250px"><h4>首页</h4></el-menu-item>
      <el-menu-item index="2" class="animate__animated animate__rubberBand"><h4>个人简介</h4></el-menu-item>
      <el-menu-item index="3" class="animate__animated animate__rubberBand"><h4>专业技能</h4></el-menu-item>
      <!-- <el-menu-item index="4" class="animate__animated animate__rubberBand"><h4>技术笔记</h4></el-menu-item> -->
    </el-menu>
  </div>
</template>
<style>
.menupage {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
}
.menupage .el-menu.el-menu--horizontal {
  border-bottom: none;
  height: 100px;
  /* background-color: #e69138; */
}
.menupage .el-menu.el-menu--horizontal > .el-menu-item,
.menupage .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 100px;  
  padding: 0px 50px;
  font-size: 16px;
  letter-spacing: 4px;
}
.menupage .el-menu--horizontal > .el-menu-item.is-active,
.menupage .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom-width: 4px;
}
.menupage .logo-title .el-icon-user {
  margin-right: 5px;
}
.menupage .el-menu-demo .el-menu-item:hover{  
  outline: 0 !important;  
  background: none !important;  
} 
</style>
<style scoped>
.logo-title {
  position: absolute;
  left: 100px;
  top: 8px;
  color: #dff3ed;
  font-size: 26px;
  cursor: pointer;
}
.logo-title img {
  width: 80px;
  outline: none;
  vertical-align: middle;
}

</style>
<script>


export default {
  props:["pre"],
  name: "TopMenu",
  data() {
    return {
      activeIndex2: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      var name = "";
      if (key === "1") name = "homepage";
      if (key === "4") name = "productpage";
      if (key === "3") name = "securityresearch";
      if (key === "2") name = "aboutus";
      this.changeView(name);
      // var targetEle = document.querySelector("." + name);
      // var offsetTop = targetEle.offsetTop;
      // document.documentElement.scrollTop = offsetTop - 150;
    },
    changeView(name){
      console.log(name);
      this.pre.conName = name;
      
    }
  },
};
</script>