<template>
  <div class="securityresearch">
    <!-- <div class="title">
      <el-divider content-position="center">个人技能</el-divider>
      <p><el-tag>el</el-tag><el-tag>springboot</el-tag><el-tag>Vue</el-tag></p>
    </div> -->
    <div class="skill">
      <el-tooltip class="item" effect="light" placement="top-end">
        <div slot="content" style="font-size:1.3em">
          ·熟练掌握JAVA语言开发技术，熟悉OOP编程思想。
          <br>
          ·熟练掌握Eclipse、AnyPoint Studio、IDEA、securecrt等开发工具。
          <br>
          ·深刻理解JavaWEB技术体系架构，分布式技术架构，微服务架构，服务器集群等技术。
          <br>
          ·熟练使用 SpringBoot、SpringCloud、SpringMVC、Spring Jpa、MyBatis、Security、Shiro、Nginx等开源框架和软件。
          <br>
          ·深刻理解MVC的架构思想和核心流程。
           <br>
          ·有过服务器 jvm等相关调优经验
        </div>
        <span class="java animate__animated animate__jackInTheBox">Java</span>
      </el-tooltip>

       <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟练掌握JS脚本语言 ajax异步调用，元素事件，交互等功能
          <br>
        </div>
        <span class="js animate__animated animate__slideInDown">JS</span>
       </el-tooltip>

        <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟练运用CSS制作各种页面样式
          <br>
        </div>
      <span class="css animate__animated animate__zoomInLeft">CSS</span>
      </el-tooltip>

      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟悉nginx反向代理配置，动静分离、域名代理、https转发等操作。
          <br>
        </div>
      <span class="echarts animate__animated animate__zoomInRight">Nginx</span>
      </el-tooltip>

      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟练使用mysql、oracle数据库编写sql语句,
           <br>
          ·熟练使用jdbc,jpa,mybatis等ORM框架
           <br>
          ·熟悉数据库调优、主从分离、分库分表、备份等各种功能
           <br>
          <br>
        </div>  
      <span class="webpack animate__animated animate__heartBeat">Sql</span>
      </el-tooltip>

      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟悉python语法，常用库
          <br>
          ·熟练运用scrapy,selenium等爬虫相关功能库
          <br>
          ·有爬虫相关工作经验
          <br>
        </div>
        <span class="python animate__animated animate__slideInUp">python</span>
      </el-tooltip>


      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·消息队列发送
          <br>
          ·减缓系统压力，实现分批处理
          <br>
        </div>
        <span class="mq animate__animated animate__slideInUp">MQ</span>
      </el-tooltip>

      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
           ·日志搜集、数据分析
          <br>
        </div>
        <span class="kafka animate__animated animate__slideInUp">kibana</span>
      </el-tooltip>
       <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
           ·Elasticsearch logstash kibana 整合剖析
          <br>
          ·文件检索、数据分析
          <br>
          ·日志储存
          <br>
        </div>
        <span class="elasticsearch animate__animated animate__slideInUp">Elasticsearch</span>
      </el-tooltip>


      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟练运用linux系统相关开发操作命令
          <br>
          ·有过服务器部署、环境配置等工作经验
        </div>
      <span class="linux animate__animated animate__wobble">linux</span>
      </el-tooltip>


      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟悉redis各类型数据基本操作
          <br>
          ·熟练运用redis进行数据缓存、单点登陆、消息队列、GEO等相关功能
           <br>
          ·redis队列,分布式全局锁的使用
        
        </div>
      <span class="redis animate__animated animate__backInDown">redis</span>
      </el-tooltip>

      <el-tooltip class="item" effect="light" placement="top-end">
         <div slot="content" style="font-size:1.3em">
          ·熟练运用vue框架进行前端交互展示
          <br>
          ·熟悉vue-cli方式进行vue项目开发
        </div>
      <span class="vue animate__animated animate__backInDown">vue</span>
      </el-tooltip>
    </div>
  </div>
</template>
<style>
.securityresearch .title p .el-tag {
  margin: 0px 5px;
  cursor: pointer;
}
.securityresearch .box-card .text {
  text-align: left;
}
</style>
<style scoped>
.securityresearch {
  background: url(../../assets/logo.jpeg) no-repeat;
  font-size: 14px;
  padding: 0px 100px;
  height: 100%;
  background-size: 100% 100%;
  animation: bg infinite 100s linear alternate;
}
.title p {
  color: #8c8888;
  font-size: 15px;
  margin-bottom: 80px;
  text-align: center;
}
.content p {
  font-size: 20px;
  color: #8c8888;
}
.skill {
  /* margin: 100px 0px; */
  padding-top: 400px;
  position: relative;
}
.skill span {
  display: inline-block;
  color: #fff;
  border-radius: 50%;
}

span.redis {
  background-color: rgba(102, 204, 136, 0.726);
  width: 60px;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  position: absolute;
  z-index: 100;
}

span.vue {
  background-color: rgba(197, 38, 236, 0.726);
  width: 60px;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  position: absolute;
  z-index: 100;
  left: 40%;
  bottom: 150px;
  margin-top: 350px;
}

span.java {
  background-color: rgb(77, 129, 206);
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 28px;
  z-index: 100;
}
span.js {
  background-color: rgba(255, 153, 102, 0.5);
  width: 130px;
  height: 130px;
  line-height: 130px;
  font-size: 26px;
  position: absolute;
  left: 43%;
  bottom: 150px;
  z-index: 0;
  margin-top: 350px;
}
span.css {
  background-color: rgba(102, 204, 204, 0.8);
  width: 90px;
  height: 90px;
  font-size: 26px;
  line-height: 90px;
  font-size: 20px;
  margin-top: 400px;
  position: absolute;
  left: 35%;
  top: 100px;
  z-index: 0;
}
span.echarts {
  background-color: rgba(255, 153, 153, 0.7);
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 24px;
  position: absolute;
  margin-top: 400px;
  left: 59%;
  bottom: 10px;
  z-index: 0;
}
span.webpack {
  background-color: rgba(255, 204, 51, 0.8);
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  position: absolute;
  left: 30%;
  top: 20px;
  z-index: 0;
  margin-top: 400px;
}
span.python {
  background-color: rgba(204, 102, 102, 0.5);
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 17px;
  position: absolute;
  left: 51%;
  bottom: -10px;
  z-index: 0;
}

span.mq {
  background-color: rgba(117, 102, 204, 0.5);
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 17px;
  position: absolute;
  left: 48%;
  top:600px;
  bottom: -10px;
  z-index: 0;
}

span.kafka {
  background-color: rgba(204, 177, 102, 0.5);
  width: 100px;
  height: 100px;
  line-height: 110px;
  font-size: 17px;
  position: absolute;
  left: 40%;
  top: 560px;
  bottom: -10px;
  z-index: 0;
}

span.elasticsearch {
  background-color: rgba(156, 204, 102, 0.5);
  width: 120px;
  height: 120px;
  line-height: 110px;
  font-size: 17px;
  position: absolute;
  left: 43%;
  top: 600px;
  bottom: -10px;
  z-index: 0;
}

span.linux {
  background-color: rgba(153, 153, 255, 0.8);
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 17px;
  position: absolute;
  left: 60%;
  top: -50px;
  z-index: 0;
  margin-top: 400px;
}
@keyframes bg {
  0% {
    background-size: 110% 130%;
  }
  10% {
    background-size: 111% 131%;
  }
  20% {
    background-size: 112% 132%;
    background-position: bottom;
  }
  30% {
    background-size: 113% 133%;
  }
  40% {
    background-size: 114% 134%;
  }
  50% {
    background-size: 115% 135%;
    background-position: left;
  }
  60% {
    background-size: 116% 136%;
  }
  70% {
    background-size: 117% 137%;
  }
  80% {
    background-size: 118% 138%;
    background-position: right;
  }
  90% {
    background-size: 119% 139%;
  }
  100% {
    background-size: 120% 140%;
  }
}
</style>
<script>
export default {
  name: "SecurityResearch",
};
</script>