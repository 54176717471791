<template>
  <div class="footer">
    <span class="scroll"></span>
    <el-link type="primary" @click="drawer = true">友情链接</el-link>
    <el-divider direction="vertical"></el-divider>
    <el-link type="primary" @click="drawer = true">联系我</el-link>
    <br />
    <br />
    <span class=""><a href="http://beian.miit.gov.cn/" style="color:rgb(57 164 175)">备案/许可证编号为：粤ICP备2021072231号</a></span>
    <el-drawer
      title="联系我"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :modal-append-to-body="false" 
    >
      <p class="info">
        <i class="el-icon-phone"></i>电话：15018441756<br />
        <i class="el-icon-message"></i>邮箱：158040416@qq.com<br />
        <!-- <i class="el-icon-message"></i>博客：https://www.cnblogs.com/HouJiao/<br /> -->
        <i class="el-icon-message"></i>github：https://github.com/tete3421<br/>
      </p>
    </el-drawer>
  </div>
</template>
<style>
.el-divider {
  background-color: rgb(84, 92, 100);
}
</style>
<style scoped>
.footer {
  height: 70px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  position: fixed;
  bottom:0;
  left:0;
  background-color:rgba(255,255,255,0.1);
  z-index:100
 
}
.footer {
  cursor: pointer;
}
.copyright {
  color: #1f98cf;
  font-size: 12px;
}
.info {
  font-size: 14px;
  color: #72767b;
  line-height: 25px;
}
.footer .scroll {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #448aff;
  background-color: rgba(68, 138, 255, 0.2);
  position: absolute;
  left: 5%;
  top: -25px;
  z-index: 10;
  animation: scrollA infinite 20s linear alternate;
}
@keyframes scrollA {
  0% {
    left: 5%;
    transform: rotate(180deg);
  }
  10% {
    left: 5%;
    transform: rotate(270deg);
  }
  20% {
    left: 5%;
    transform: rotate(450deg);
  }
  25% {
    left: 10%;
    transform: rotate(540deg);
  }
  30% {
    left: 20%;
    transform: rotate(720deg);
  }
  35% {
    left: 30%;
    transform: rotate(900deg);
  }
  40% {
    left: 40%;
    transform: rotate(1080deg);
  }
  45% {
    left: 50%;
    transform: rotate(1260deg);
  }
  50% {
    left: 60%;
    transform: rotate(1440deg);
  }
  55% {
    left: 70%;
    transform: rotate(1620deg);
  }
  60% {
    left: 80%;
    transform: rotate(1800deg);
  }
  80% {
    left: 90%;
    transform: rotate(2610deg);
  }
  90% {
    left: 90%;
    transform: rotate(2340deg);
  }
  100% {
    left: 90%;
    transform: rotate(2520deg);
  }
}
</style>
<script>
export default {
  name: "Footer",
  data() {
    return {
      drawer: false,
      direction: "btt",
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
  },
};
</script>