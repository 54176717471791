<template>
  <div id="app">
    <TopMenu :pre="pre" />
    <SecurityResearch v-show="pre.conName == 'securityresearch'" />
    <HomePage v-show="pre.conName == 'homepage'" />
    <AboutUs v-show="pre.conName == 'aboutus'" />
    <Footer/>
  </div>
</template>

<script>
import TopMenu from "./components/TopMenu.vue";
import Footer from "./components/footer/Footer.vue";
import HomePage from "./components/home/HomePage.vue";
import SecurityResearch from "./components/security/SecurityResearch.vue";
import AboutUs from "@/components/AboutUs/AboutUs.vue";

export default {
  name: "App",
  components: {
    TopMenu,
    Footer,
    HomePage,
    SecurityResearch,
    AboutUs
  },
  data() {
    return {
      pre: {
        conName: "homepage",
      },
    };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: -0.47rem;
  /* background: url(./assets/logo.jpeg) no-repeat; */
}
</style>
